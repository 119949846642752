import Vue from 'vue';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(Viewer);
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  title: false,
  // toolbar: false,
  navbar: false,
});

// <viewer :images="thumbImgs" :title="false" :toolbar="1">
// <img v-for="src in fullImgs" :key="src" :src="src" />
// </viewer>
