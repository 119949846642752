<template>
  <nav class="header">
    <div class="container">
      <div class="container-mx-between">
        <div class="header-logo">
          <img src="@/assets/logo.png" alt="logo" height="32" />
          <span class="header-title">成都赛珂琪科技有限公司</span>
        </div>
        <div class="header-menu">
          <ul>
            <li v-for="(item, index) in menus" :key="index" class="header-menu-item">
              <router-link :to="item.path" exact>{{ item.title }}</router-link>
            </li>
            <li class="header-menu-item"><a href="http://user.zn98.com" target="_blank">用户中心</a></li>
            <li class="header-menu-item"><a href="http://user.zn98.com/#/registereduser" target="_blank">典型客户</a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'NavHeader',
    data() {
      return {
        menus: [
          { title: '首页', path: '/' },
          { title: '医疗软件', path: '/ylrj' },
          { title: '医疗云系统', path: '/ylrjYun' },
          { title: '行业软件', path: '/hyrj' },
          { title: '行业云系统', path: '/hyrjYun' },
          { title: '手机APP', path: '/app' },
          { title: '软件定制', path: '/rjdz' },
          { title: '产品购买', path: '/cpgm' },
        ],
      };
    },
  };
</script>

<style lang="less" scoped>
  a {
    font-size: @text-font-size;
    margin-right: 3px;
    padding: 8px 15px;
  }
  a:hover,
  .router-link-active {
    color: #fff;
    background-color: @accent-color;
  }
</style>
