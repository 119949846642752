<template>
  <div id="app">
    <!-- 导航 -->
    <nav-header></nav-header>
    <!-- 轮播 -->
    <nav-swiper v-if="isShow" />
    <!-- 内容 -->
    <router-view />
    <!-- 页脚 -->
    <nav-footer />
  </div>
</template>

<script>
  import NavHeader from '@/views/layout/NavHeader.vue';
  import NavFooter from './views/layout/NavFooter.vue';
  import NavSwiper from './views/layout/NavSwiper.vue';

  export default {
    components: { NavHeader, NavFooter, NavSwiper },
    data() {
      return {
        isShow: true, //移动APP不显示轮播图
      };
    },
    created() {},
    watch: {
      $route: {
        handler(val) {
          this.isShow = val.fullPath.substring(0, 4) !== '/app';
        },
      },
    },
    methods: {
      changeIsShow() {
        this.data.isShow = false;
      },
    },
  };
</script>

<style lang="less" scoped></style>
