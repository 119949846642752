<template>
  <div class="footer">
    <div class="container">
      <ul class="container-mx-between">
        <li class="foot-ul-li line">
          <b>版权所有</b>
          <p>版权所有：©1996-{{ toYear }} zn98.com </p>
          <p>公司名称：成都赛珂琪科技有限公司</p>
          <p>Chengdu Saikeqi Technology Co., Ltd</p>
          <p>公司官网：<a href="http://www.zn98.com/" target="_blank">www.zn98.com</a></p>
          <p>公司地址：四川成都一环路南三段78号</p>
        </li>
        <li class="foot-ul-li line">
          <b>联系我们</b>
          <p>业务联系：蒋先生</p>
          <p>联系手机：1918 098 1897</p>
          <p>联系ＱＱ：281465990</p>
          <p>联系微信：1918 098 1897</p>
          <p>电子邮件：zn98@vip.qq.com</p>
          <p>公司电话：028-82206289</p>
        </li>
        <li class="foot-ul-li line">
          <b>联系微信</b>
          <viewer ref="viewer">
            <template scope="scope">
              <div class="viewer-cfq">
                <img src="../../assets/images/website/LianxiWeixin.jpg" width="150" height="150" />
                {{ scope.options }}
              </div>
            </template>
          </viewer>
        </li>
        <li class="foot-ul-li">
          <b>备案信息</b>
          <p><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备19035750号</a></p>
          <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010602001026" target="_blank">川公网安备51010602001026号</a></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavFooter',
    data() {
      return {
        toYear: new Date().getFullYear(),
      };
    },
  };
</script>

<style lang="less" scoped>
  .footer {
    padding: 30px 0;
    // background-color: #d8d8d8;
    border-top: 1px solid @border-color;
  }

  .container-mx-center,
  .container-mx-center a {
    color: #555;
  }
  .container-mx-center a:hover {
    color: @accent-color;
  }

  .foot-ul-li {
    width: 250px;
    padding: 0 20px;
    b {
      font-size: @text-font-size;
      display: block;
      margin-bottom: 10px;
    }
    p {
      font-size: 12px;
    }
  }

  .line {
    border-right: 1px solid @border-color;
  }
</style>
