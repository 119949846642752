<template>
  <div class="swiper">
    <div class="container">
      <swiper class="swiper-wrapper" :options="swiperOptions" ref="mySwiper" v-if="showSwiper">
        <!-- slides -->
        <swiper-slide v-for="item of slideList" :key="item.id" class="swiper-slide">
          <a :href="'#/product/' + item.id">
            <img :src="item.img" alt="" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  export default {
    name: 'NavSwiper',
    components: { Swiper, SwiperSlide },
    data() {
      return {
        slideList: [
          {
            id: '1',
            img: '/slider/slide-1.jpg',
          },
          {
            id: '2',
            img: '/slider/slide-2.jpg',
          },
          {
            id: '3',
            img: '/slider/slide-3.jpg',
          },
          // {
          //   id: '4',
          //   img: '/slider/slide-4.jpg',
          // },
        ],
        swiperOptions: {
          loop: true,
          autoplay: { delay: 2000, stopOnLastSlide: false, disableOnInteraction: false },
          effect: 'fade',
          speed: 1000,
          autoHeight: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true, // 点击分页器跳切换到相应的幻灯片
          },
        },
      };
    },
    computed: {
      showSwiper() {
        return this.slideList.length;
      },
    },
  };
</script>

<style lang="less" scoped>
  //轮播图
  .swiper-wrapper,
  .swiper-slide img {
    width: 100%;
    height: 360px;
  }
  .swiper-wrapper {
    .swiper-slide {
      background-position: center;
      background-size: cover;
    }
  }
</style>
