import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/ylrj',
    name: 'ylrj',
    component: () => import('@/views/ylrj'),
  },
  //医疗软件下载页
  {
    path: '/ylrjDownload',
    name: 'ylrjDownload',
    component: () => import('@/views/ylrj/YlrjDownload.vue'),
  },
  {
    path: '/ylrjYun',
    name: 'ylrjYun',
    component: () => import('@/views/ylrjYun'),
  },
  {
    path: '/hyrj',
    name: 'hyrj',
    component: () => import('@/views/hyrj'),
  },
  //行业软件下载页
  {
    path: '/hyrjDownload',
    name: 'hyrjDownload',
    component: () => import('@/views/hyrj/HyrjDownload.vue'),
  },
  {
    path: '/hyrjYun',
    name: 'hyrjYun',
    component: () => import('@/views/hyrjYun'),
  },
  {
    path: '/rjdz',
    name: 'rjdz',
    component: () => import('@/views/rjdz'),
  },
  {
    path: '/cpgm',
    name: 'cpgm',
    component: () => import('@/views/cpgm'),
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/App/AppIndex.vue'),
  },

  //error一定要写在routes的最后面
  {
    path: '*',
    name: 'nofound',
    component: () => import('/public/page/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
